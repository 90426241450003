.payments-page {
  background-color: #f0f0f0;
  padding-top: 130px;
  min-height: 100vh;

  .payments-section {
    .container {
      max-width: 800px;
      margin: 0 auto;
      padding: 20px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      animation: fadeIn 0.5s ease-in-out;
    }

    .payments-title {
      font-size: 24px;
      color: #333;
      margin-bottom: 20px;
      text-align: center;
    }

    .payments-list {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    .payment-card {
      padding: 15px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
      }

      &.paid {
        background-color: #e0ffe0; /* Green background if paid */
        border-left: 5px solid #2ecc71;
      }

      &.unpaid {
        background-color: #ffe0e0; /* Red background if unpaid */
        border-left: 5px solid #e74c3c;
      }

      &.discounted {
        border-left: 5px solid #f1c40f; /* Yellow border for discount */
      }

      .payment-info {
        h3 {
          font-size: 20px;
          color: #333;
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          color: #666;
          margin: 5px 0;
        }
      }
    }
  }
}

/* Animatsiya */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.date-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
}

.date-select {
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  outline: none;
  appearance: none; /* Select element stilini oddiy qilib beradi */

  &:hover {
    border-color: #3498db;
  }

  &:focus {
    border-color: #2980b9;
    background-color: #fff;
  }

  option {
    background-color: #fff;
    color: #333;
  }
}

/* Responsive uchun */
@media (max-width: 768px) {
  .date-wrapper {
    flex-direction: column;
    gap: 10px;
  }

  .date-select {
    width: 100%;
  }
}
