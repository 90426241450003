.ad-teacher{
  
  .ad-teacher-action-body{
    display: none;
    position: absolute;
    border-radius: 5px;
    right: 10px;
    top: 52px;
    background-color: rgb(245, 241, 241);
    z-index: 10;
    *{
      width: 100%;
    }
  }
  


  
  @media only screen and (max-width: 500px){
    .btn-action{
      margin-right: 10px !important;
    }
    .mygroup__table-head{
      .login{
        display: none !important;
      }
    }
  }

  
  
  
}



.total_payment {
  // background-color: #f9f9f9;
  padding: 20px;
  padding-top: 0;
  border-radius: 10px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  // max-width: 400px;
  // margin: 20px auto;
  
  .select_wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    // margin-bottom: 20px;

    select {
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      background-color: #fff;
      color: #333;
      font-size: 16px;
      width: 20%;
      // width: 45%;
      cursor: pointer;

      &:focus {
        outline: none;
        border-color: #daa520;
        box-shadow: 0 0 8px rgba(218, 165, 32, 0.3);
      }
    }
  }

  .total_payment_info {
    background-color: #fff;
    padding: 5px 15px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    display: flex;
    gap: 10px;
    flex-direction: row;

    p {
      margin: 10px 0;
      font-size: 14px;
      color: #333;

      &:first-child {
        font-weight: bold;
        color: #ff4c4c; /* To'lanishi kerak bo'lgan summaga qizil rang */
      }

      &:nth-child(2) {
        color: #28a745; /* To'langan summaga yashil rang */
      }

      &:last-child {
        color: #f39c12; /* Chegirma uchun sariq rang */
      }
    }
  }
}

@media (max-width: 500px) {
  .total_payment {
    padding: 15px;
    .select_wrapper {
      flex-direction: column;
      gap: 15px;

      select {
        width: 100%; /* Mobil qurilmalar uchun kengaytirilgan */
      }
    }

    .total_payment_info {
      padding: 10px;

      p {
        font-size: 14px; /* Kichik ekranlar uchun shrift kattaligi kichraytirilgan */
      }
    }
  }
}

