.mygroup__btn{
  margin-top: 30px;
  display: flex;
  justify-content: end;
  gap: 10px;
}

@media only screen and (max-width: 830px) {
  .tech-attendance{
    .mygroup__table-body--item{
      align-items: normal !important;

      & > div{
        &:nth-child(2){
          width: 70% !important;
        }
        &:nth-child(3){
          width: 30% !important;
        }
      }
    }
  }

  .tech-attendance-mobile{
    .mygroup__table-head{
      &:nth-child(3){
        padding: 3px 5px;
      }
    }
  }
}

@media only screen and (max-width: 520px) {
  .tech-attendance{
    .mygroup__table-body--item{
      & > div{
        &:nth-child(2){
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: green;
          width: 100% !important; 
          max-width: 300px; /* istalgan o'lchamdagi miqdorni o'zgartiring */
        }
        &:nth-child(3){
          width: 120px;
        }
      }
    }
  }
}

.none{
  display: none;
}

.adAttendanceAddStudent{
  padding-top: 20px;
}

.adAttendanceAddStudent-main{
  padding-top: 136px;
}

.wrapper-date{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.date-input-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 300px;

  .date-input {
    width: 100%;
    padding: 10px 15px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    transition: all 0.3s ease;
    color: #333;
    background-color: #f7f7f7;
    box-shadow:  0 2px 5px rgba(0, 0, 0, 0.1);

    &:hover {
      border-color: #66afe9;
      background-color: #fff;
    }

    &:focus {
      border-color: #66afe9;
      box-shadow: 0 0 8px rgba(102, 175, 233, 0.6);
      background-color: #fff;
    }

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      right: 10px;
      color: #666;
      opacity: 0.7;
      cursor: pointer;
    }
  }
}

// ########### AdAttendanceDateStudent ###########

.AdAttendanceDateStudent{
  .wrapper-date{
    a{
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }
}

.add-fixed{
  *{
    color: white;
  }
}