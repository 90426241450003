.header-section {
  min-height: 58px;
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 9999;

  .header__nav {
    max-width: 1320px;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .desktop-menu {
      display: flex;
      gap: 40px;

      li {
        a {
          font-size: 18px;
          color: black;
        }
      }
    }

    .menu-bar {
      display: none; // Desktopda yashirish
    }

    .logout {
      display: block;
      margin-left: auto;
      color: green;
    }
  }

  .mobile-menu {
    display: none; // Yashirilgan holatda bo'ladi
  }

  // Mobil menyu ko'rinishi (faqat kichik ekranlar uchun)
  @media (max-width: 768px) {
    .header__nav {
      .desktop-menu {
        display: none; // Desktop menyusini yashirish
      }

      .menu-bar {
        display: block; // Faqat mobilda menyu tugmasi ko'rinadi
      }

      .mobile-menu {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
        padding: 10px;
        position: absolute;
        top: 60px;
        right: 10px;
        z-index: 999;
        width: 80%;

        li {
          margin: 10px 0;

          a {
            font-size: 18px;
            color: black;
          }
        }

        .logout {
          margin-top: 20px;
        }

        .active {
          color: green;
        }
      }
    }
  }
}

.logout_btn{
  // color: green;
  // cursor: pointer;
  padding: 10px 15px;
  border-radius: 5px;
  &:hover{
    background-color: green;
    *{
      color: white;
    }
  }
}
