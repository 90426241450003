.mygroup-section {
  .mygroup__table {
    .add-wrapper {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      border-radius: none !important;
      // border: none;
      gap: 10px;
      button {
        outline: none;
      }
    }

    .btn-delete{
      border: none;
      background-color: transparent;
      padding: 5px 20px;
      border-radius: 5px;
      cursor: pointer;
      
      &:hover{
        background-color: rgba(25, 118, 210, 0.04);
      }
    }

  }
  .menu{
    padding: 0 20px;
  }
}

.tech-lesson-section{
  .add-wrapper{
    display: flex;
    justify-content: center;
    height: 65px;

    span{
      padding: 0 20px;
    }
  }
}

.adpayment{
  .mygroup__table .add-wrapper{
    display: flex !important;
    justify-content: flex-start !important;
  }
}