h1{
  font-size: 30px;
}

// .admin-single__header{
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   margin: 40px;
//   // margin-top: 100px;
//   cursor: pointer;
//   color: black;
// }

.admin-single__wrapper{
  margin: 20px 20px;
  padding: 20px;
  border: 1px solid;

  div{
    padding: 10px 0;
  }
}

.admin-single{
  padding-top: 130px;
}