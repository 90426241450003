.statistics-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background: #f5f9fc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #3a7d44;
  font-weight: bold;
}

.stat-box {
  display: flex;
  justify-content: space-around;
  gap: 20px;
}

/* Mobil ekranlarga moslashtirish */
@media (max-width: 600px) {
  .stat-box {
    flex-direction: column;
    gap: 15px;
  }
  
  .title {
    font-size: 20px;
  }
}

.stat-item {
  flex: 1;
  padding: 20px;
  background: #eaf6f0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stat-item h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #1b5e20;
}

.stat-item p {
  font-size: 24px;
  font-weight: bold;
  color: #2e7d32;
}

.loading {
  margin-top: 40px;
  text-align: center;
  font-size: 18px;
  color: #666;
}
