// Container style for the whole profile section
.profile-section {
  // background-color: #f4f6f9;
  padding: 20px;
  // min-height: 100vh;

  .container {
    // max-width: 800px;
    margin: 0 auto;
    padding: 0;
  }

  .profile-card {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    // padding: 30px;
  }

  .profile-header {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    .profile-img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      object-fit: cover;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    .user-info {
      h2 {
        font-size: 24px;
        font-weight: bold;
        margin: 0;
        color: #333;
      }

      .role {
        font-size: 14px;
        color: #666;
      }
    }
  }

  .profile-details {
    h3 {
      font-size: 18px;
      color: #333;
      margin-bottom: 10px;
    }

    .details-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;

      .detail-item {
        background-color: #f9f9f9;
        padding: 15px;
        border-radius: 8px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);

        strong {
          display: block;
          font-size: 12px;
          color: #999;
        }

        p {
          font-size: 14px;
          margin: 5px 0 0;
          color: #333;
        }
      }
    }
  }

  // Loader for loading state
  .loader {
    font-size: 18px;
    color: #333;
    text-align: center;
    padding: 50px 0;
  }

  // No-data state
  .no-data {
    text-align: center;
    font-size: 18px;
    color: #f44336;
    padding: 50px 0;
  }
}

// Telefonlar uchun media queries
@media (max-width: 768px) {
  .profile-card {
    padding: 20px; // Paddingni kichikroq qilamiz

    .profile-header {
      flex-direction: column; // Telefonlar uchun Ma`lumotlar ustma-ust joylashtiriladi
      text-align: center;
      gap: 10px;

      .profile-img {
        width: 60px; // Rasmni kichikroq qilamiz
        height: 60px;
      }

      .user-info {
        h2 {
          font-size: 20px; // Matn o'lchamini kamaytiramiz
        }

        .role {
          font-size: 12px;
        }
      }
    }

    .profile-details {
      h3 {
        font-size: 16px; // Sarlavhani kichikroq qilamiz
      }

      .details-grid {
        grid-template-columns: 1fr; // Telefonlarda barcha elementlarni bir ustunga joylashtiramiz

        .detail-item {
          padding: 10px; // Paddingni kichikroq qilamiz
          p {
            font-size: 13px;
          }
        }
      }
    }
  }
}

// Kichik telefonlar uchun (iPhone SE kabi)
@media (max-width: 480px) {
  .profile-card {
    padding: 15px;

    .profile-header {
      .profile-img {
        width: 50px; // Eng kichik ekranda rasm o'lchamini yanada kichiklashtiramiz
        height: 50px;
      }

      .user-info {
        h2 {
          font-size: 18px; // Matnni kichikroq qilamiz
        }

        .role {
          font-size: 11px;
        }
      }
    }

    .profile-details {
      h3 {
        font-size: 14px;
      }

      .details-grid {
        .detail-item {
          padding: 8px; // Yana kichikroq padding
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
}
