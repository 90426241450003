.mygroups-section {
  padding: 20px 0;
  // background-color: #f4f4f4;

  .container {
    max-width: 900px;
    margin: 0 auto;
  }

  .mygroup {
    .mygroup__table {
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      width: 100%;

      &.compact {
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: 15px;
      }

      &-head {
        display: grid;
        grid-template-columns: 1fr 2fr 2fr 1fr;
        background-color: #3498db;
        color: white;
        padding: 10px 15px;
        font-weight: bold;
      }

      &-body {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .mygroup__table-body--item {
          display: grid;
          grid-template-columns: 1fr 2fr 2fr 1fr;
          padding: 10px 15px;
          background-color: #fff;
          border-radius: 6px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          transition: all 0.3s ease-in-out;

          &:hover {
            transform: translateY(-3px);
            background-color: #f0faff;
          }

          .group-name a {
            color: #3498db;
            text-decoration: none;
            font-weight: 600;
            transition: color 0.3s ease;
          cursor: pointer;


            &:hover {
              color: #2d88c5;
            }
          }

          .price {
            color: #2ecc71;
            font-weight: bold;
          }
        }

        .animated-item {
          animation: fadeInUp 0.5s ease-in-out;
        }
      }
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 768px) {
    .mygroup__table-head {
      grid-template-columns: 1fr 2fr 1fr;
    }

    .mygroup__table-body--item {
      grid-template-columns: 1fr 2fr 1fr;
    }
  }
}


// My Groups single
.section-myGroupsSingle {
  padding: 50px 0;
  // background-color: #f9f9f9;
  
  .container {
    display: flex;
    justify-content: center;
  }

  .myGroupsSingle {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    
    &__wrapper {
      max-width: 485px; // Kattaroq hajm
      width: 100%;
      // max-height: 215px;
      // height: 100%;
    }

    &__title {
      font-size: 30px; // Matn hajmini oshirish
      color: #333;
      margin-bottom: 30px; // Bo'sh joyni ko'paytirish
    }

    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 25px; // Elementlar orasidagi bo'shliqni oshirish
    }

    &__item {
      position: relative;
      background-color: #ffffff;
      width: 100%;
      cursor: pointer;
      border-radius: 12px; // Burchaklarni kattalashtirish
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); // Kattaroq soyalar
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
        
        .myGroupsSingle__link {
          color: #3498db;
        }
      }
    }

    &__link {
      display: inline-block;
      text-decoration: none;
      color: #333;
      font-size: 22px; // Matn hajmini oshirish
      font-weight: 600;
      letter-spacing: 0.5px;
      position: relative;
      padding: 95px 105px; // Paddingni oshirish

      
      &::before {
        content: "";
        position: absolute;
        width: 50%;
        right: 0;left: 0;
        margin: 0 auto;
        height: 3px;
        background-color: #3498db;
        bottom: 85px;
        left: 0;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.3s ease;
      }

      &:hover::before {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }
}

// Mobil versiya uchun o'lcham va stil o'zgartirish
@media (max-width: 768px) {
  .section-myGroupsSingle {
    padding: 30px 0;

    .myGroupsSingle__list {
      width: 100%;
      height: 100%;
      gap: 20px; // Elementlar orasidagi bo'shliqni oshirish
    }

    .myGroupsSingle__item {
      max-width: 485px;
      max-height: 215px;
      height: 100%;
      // padding: 95px 200px; // Paddingni biroz kamaytirish
    }

    .myGroupsSingle__link {
      font-size: 20px; // Kattaroq matn hajmi
    }
  }
}


// ========= MyGroupsSingleAttends =========

.attendance-list {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 100px;
  gap: 10px;
  max-width: 400px;
  margin: 0 auto;
}

.attendance-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeInUp 0.5s ease both;
}

.attendance-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.attendance-date {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.attendance-action {
  font-size: 18px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 6px;
}

.action-here {
  background-color: black;
  color: white;
}

.action-ask {
  background-color: green;
  color: white;
}

.action-no-ask {
  background-color: red;
  color: white;
}

/* Animation */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .attendance-item {
    padding: 10px;
  }

  .attendance-date {
    font-size: 14px;
  }

  .attendance-action {
    font-size: 16px;
  }
}

.no-data {
  text-align: center;
  font-size: 18px;
  color: red;
}


// ========== MyGroupsSingleTest =========

.tests-list {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
  max-width: 600px;
  margin: 0 auto;
}

.test-item {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeInUp 0.5s ease both;
}

.test-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.test-name {
  font-size: 22px;
  font-weight: bold;
  color: #333;
}

.test-details {
  margin-top: 10px;
}

.test-max-point,
.test-self-point,
.test-rating,
.test-date {
  font-size: 16px;
  color: #666;
}

.no-data {
  text-align: center;
  font-size: 18px;
  color: red;
}

/* Animation */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .test-item {
    padding: 15px;
  }

  .test-name {
    font-size: 20px;
  }

  .test-max-point,
  .test-self-point,
  .test-rating,
  .test-date {
    font-size: 14px;
  }
}
