// .ad-student{

//   .mygroup__table{
//     thead{
//       background-color: rgb(255, 187, 0);  
//     }}
  
//   .adstudent-action-body{
//     display: none;
//     position: absolute;
//     border-radius: 5px;
//     right: 10px;
//     top: 52px;
//     background-color: rgb(245, 241, 241);
//     z-index: 10;
//     *{
//       width: 100%;
//     }
//   }
  
//   @media only screen and (max-width: 500px){
//     .btn-action{
//       margin-right: 10px !important;
//     }
//     .mygroup__table-head{
//       .login{
//         display: none !important;
//       }
//     }
//     .adminAddStudentForm{
//       // *{
//       //   width: 100% !important;
//       // }
//       background-color: crimson !important;
//     }
//   }
  
// }

.mygroup-section {
  // background-color: #f9f9f9;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  padding: 20px 0;

  .mygroup__table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);


    th, td {
      padding: 15px 20px;
      text-align: left;
    }

    th {
      background-color: #3498db;
      color: #fff;
      font-weight: bold;

      &.add-wrapper{
        *{
          color: #fff;}
      }
    }

    td {
      background-color: #fff;
      color: #333;
      vertical-align: middle;
      border-bottom: 1px solid #ddd;

      &.add-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    tbody tr:hover {
      background-color: #f4f9fd;
      // cursor: pointer;
    }

    .btn-delete {
      background-color: crimson;
      border: none;
      *{
        color: #fff;
      }
      padding: 5px 10px;
      border-radius: 4px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darkred;
      }
    }
  }

  .mobile {
    display: none;
  }

  .btn-action {
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
  }

  .adstudent-action-body {
    display: none;
    position: absolute;
    right: 0;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 10;

    .btn-delete {
      width: 100%;
      margin-top: 10px;
      background-color: crimson;
      color: #fff;
      padding: 5px 0;
      border-radius: 4px;
    }
  }

  .btn-action:focus + .adstudent-action-body,
  .btn-action:hover + .adstudent-action-body {
    display: block;
  }

  @media (max-width: 768px) {
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
      width: 100%;
    }

    .mygroup__table-body--item {
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      padding: 15px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-3px);
      }

      .group-name {
        flex: 2;
        font-size: 16px;
        color: #3498db;
        text-decoration: none;
      }

      .adstudent-action-body {
        display: none;
      }

      .btn-action {
        font-size: 18px;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }

      .btn-action:hover ~ .adstudent-action-body {
        display: block;
        position: absolute;
        right: 0;
        top: 40px;
        background-color: #fff;
        border: 1px solid #ddd;
        padding: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        z-index: 10;
      }
    }

    .btn-delete {
      width: 100%;
      background-color: crimson;
      color: #fff;
      padding: 5px 0;
      border-radius: 4px;
      margin-top: 10px;
    }
  }
}
