.navbar {
  background-color: #f8f8f8;
  // padding: 0 20px;
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  align-items: center;

  * {
    transition: all 0.03s ease !important; /* universal transition toza yozildi */
  }

  ul {
    width: 100%;
    display: flex;
    // justify-content: space-between;

    li {
      list-style: none;
      transition: all 0.03s ease;
      width: 100%;
      
      a {
        width: 100%;
        display: inline-block;
        text-align: center;
        padding: 10px 20px;
        text-decoration: none;
        cursor: pointer;
        font-size: 24px;
        color: rgba(111, 117, 119, 0.9);
      }

      &:hover {
        // filter: blur(0.5px);
        background-color: rgba(111, 117, 119, 0.179);
      }
    }
  }

  .active{
    background-color: royalblue;
    *{
      color: white;
    }
  }
}
